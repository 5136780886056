import styled from 'styled-components';

export const Overlay = styled.div`
  width: calc(100% - 12px);
  height: calc(100% - 12px - 42px);
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  top: calc(42px + 6px);
  left: 6px;
  padding: 6px;
  box-sizing: border-box;
  border-radius: 0 0 5px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  width: max-content;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  gap: 10px;
  background: #ffffff;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
`;
