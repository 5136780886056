import styled from 'styled-components';

const Base = styled.input`
  border: 1px solid #e8e8e8;
  padding-left: 4px;
`;

interface PasswordConfirmInputProps {
  value: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

function PasswordConfirmInput({ value, onChange }: PasswordConfirmInputProps) {
  return <Base value={value} onChange={onChange} type="password"></Base>;
}

export default PasswordConfirmInput;
