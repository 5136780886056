import { useState } from 'react';

import PasswordConfirmInput from './PasswordConfirmInput';
import Typography from '@src/app/common/components/Typography';
import styled from 'styled-components';
import { Container, Overlay } from './styled';

interface PasswordConfirmInputProps {
  onClose: () => void;
}

function PasswordConfirm({ onClose }: PasswordConfirmInputProps) {
  const [password, setPassword] = useState('');

  function handlePasswordChange(e: React.ChangeEvent<HTMLInputElement>) {
    setPassword(e.target.value);
  }

  function handleCreate() {
    if (!password) {
      alert('비밀번호를 입력해주세요.');
      return;
    }
    onClose();
  }

  return (
    <Overlay onClick={onClose}>
      <Container>
        <Typography kind="body1" as="p">
          비밀번호를 입력하세요.
        </Typography>
        <PasswordConfirmInput value={password} onChange={handlePasswordChange}></PasswordConfirmInput>
        <button onClick={handleCreate}>생성</button>
      </Container>
    </Overlay>
  );
}
export default PasswordConfirm;
